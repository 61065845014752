@import "../../../styles/const.module";
@import "../../../styles/mixins.module";

.card {
  position: relative;
  @include box-style();
  width: 420px;
  min-height: 150px;
  padding: 0 16px;
  margin-top: 16px;
  margin-right: 16px;

  transition: all 1s ease;
  background-color: $color-black;
  border-radius: 8px;

  @media screen and (max-width: $change-header-width) {
    min-width: 343px;
    width: calc(100vw - 32px);
    margin: 0;
    margin-top: 8px;
    margin-right: 0;
  }
}

.headerCard {
  padding: 20px 0 0 3px;
  ;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.dragImg {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  padding: 0 10px 8px 0;
}

.wrapperItems {
  transition: .3s;
  overflow: hidden;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 33px;
  column-gap: 8px;
  flex-wrap: wrap;
  padding: 16px 0;
  transition: all .3s ease;

  &>div:last-child {
    margin-right: 0;
  }
}

.domesticItem {
  &>div {
    margin-bottom: 15px;
  }

  &>div:last-child {
    margin-bottom: 0;
  }
}

.tick-slider {
  position: relative;
  width: 100%;
}

.background {
  width: 100%;
  background-color: #60BF69;
}