.checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-checkbox {
    display: inline-flex;
    align-items: center;
    user-select: none;
    position: relative;
}

.custom-checkbox::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
}

.customImg {
    position: absolute;
    top: 0;
    left: 0;
}