.logout {
  display: inline-flex;
  align-items: center;
  color: #60BF69;
  transition: .3s;
  cursor: pointer;

  &:hover {
    //color: #498D4F;
    opacity: 0.8;
  }

  &:hover .iconLogout {
    opacity: 0.8;
  }

  .text {
    margin: 0 0 0 10px;
  }
}

