.rangeWrapper {
	display: flex;
	align-items: flex-end;
	//margin: 8px 20px 8px 0;
	& > p:last-child {
		margin: 0 0 0 6px;
	}
}

.range {
	width: 185px;
	height: 40px;
	position: relative;
}

.rangeInput {
	width: 100%;
	height: 2px;
	-webkit-appearance: none;
	outline: none;
	//opacity: 0.7;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	border-radius: 16px;
	//&:hover {
	//  opacity: 1;
	//}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 10px;
		height: 10px;
		background-color: #60bf69;
		border-radius: 50%;
		border: 2px solid #ececec;
		cursor: pointer;
	}

	&::-moz-range-thumb {
		width: 10px;
		height: 10px;
		background-color: #60bf69;
		border-radius: 50%;
		border: 2px solid #ececec;
		cursor: pointer;
	}
}

.rangeNum {
	white-space: nowrap;
	margin: 0;
	position: absolute;
	top: 8px;
	left: 0;
	height: 20px;
	font-family: Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #ececec;
}

.range > input[type='range'] {
	position: absolute;
	bottom: 3px;
}

.scaleValue {
	font-family: Arial, sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 12px;
	color: #adadad;
	margin: 0 2px 0 0;
}

.description {
	margin: 2px 0 0 0;
	padding: 0;
	font-family: Arial, sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 12px;
	color: #adadad;
}

.adjustmentForm {
	display: flex;
	width: 100%;
	gap: 20px;
	margin: 20px 0 2px;
}

.adjustment {
	width: 32px;
	height: 32px;
	border-radius: 8px;
	outline: none;
	border: none;
	background-color: #60bf69;
	display: flex;
	justify-content: center;
	align-items: center;
}

.adjustmentInput {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 99px;
	height: 32px;
	border-radius: 8px;
	border: none;
	outline: none;
	background: #212121;
	box-shadow: 4px 4px 8px 0px #121212 inset,
		-4px -4px 8px 0px rgba(121, 121, 121, 0.2) inset;
	text-align: center;
	font-family: Noto Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: #ececec;
}
