@import "../../../styles/mixins.module";
@import "../../../styles/const.module";

.wrapperAvatar {
  position: relative;
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0;

  @include box-style();
  width: 32px;
  height: 32px;
  margin-left: 25px;

  @media screen and (max-width: $change-header-width) {
    width: 40px;
    height: 40px;
    margin-left: 0;
    cursor: pointer;
  }
}

.avatar {
  display: block;
  object-fit: cover;
  object-position: center;
  overflow: hidden;

  @include box-style();
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.online {
  position: absolute;
  right: 2px;
  bottom: 2px;

  @include box-style();
  width: 8px;
  height: 8px;

  border: 1px solid white;
  border-radius: 50%;
  background-color: $color-green1;
}

.name {
  @include box-style();
  margin-left: 8px;

  font-family: 'Roboto', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $color-gray-light;

  @media screen and (max-width: $change-header-width) {
    display: none;
  }
}

.upload{
  display: none;
}

.uploadLabel {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}