.onOffWrapper {
  margin: 8px 30px 8px 0;
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.value {
  padding: 0;
  margin: 0 0 0 7px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #ECECEC;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}


.description {
  padding: 0;
  margin: 5px 0 0 0;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #ADADAD;
}