$max-width: 1440px;
$min-width: 375px;

$change-form-width: 900px;
$change-style-width: 750px;
$change-header-width: $change-style-width;

$color-gray-light: #ECECEC;
$color-gray-medium: #ADADAD;
$color-gray-dark: #8B8B8B;
$color-black-dark: #1A1A1A;
$color-black: #212121;

$color-green1: #60BF69;

:export {
  changeHeaderWidth: $change-header-width;
  colorGrayLight: $color-gray-light;
  colorGrayMedium: $color-gray-medium;
  colorBlack: $color-black;
  colorGreen: $color-green1;
}
