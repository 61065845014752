@import "../../../../styles/mixins.module";
@import "../../../../styles/const.module";

@mixin greeting-text() {
  @include box-style();
  width: 100%;
  font-family: 'Noto Sans', Arial, sans-serif;
  text-align: center;
}

.wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: $max-width;
  min-width: $min-width;

  max-height: 100vh;
  min-height: 375px;
  height: 100vh;

  padding: 0 calc((100% - 1440px) / 2);
  margin: 0;
  transition: 0.5s ease-in-out;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $max-width) {
    width: 100%;
    padding: 0;
  }
}

.leftSide {
  @include box-style();
  max-width: 517px;
  min-width: $min-width;
  width: 100%;
  height: 100vh;

  background-color: $color-gray-light;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $change-style-width) {
    display: none;
  }
}

.rightSide {
  position: relative;
  @include box-style();
  max-width: 923px;
  min-width: $min-width;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.mainImage {
  display: block;
  @include box-style();
  width: 100%;
  height: 100%;

  object-fit: contain;
  object-position: center;
}

.greetingTitleLeft {
  @include greeting-text();
  padding: 0 20px;

  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  color: $color-black-dark;
}

.greetingTitleRight {
  position: absolute;
  top: 0;
  left: 0;

  display: none;
  @include greeting-text();
  padding-top: 60px;

  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  color: white;

  @media screen and (max-width: $change-style-width) {
    display: block;
  }
}