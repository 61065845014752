.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}
.ml-auto {
  margin-left: auto;
}


.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-25 {
  margin-right: 25px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}


.p-10 {
  padding: 10px;
}