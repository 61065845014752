@import "../../../../styles/mixins.module";
@import "../../../../styles/const.module";

.wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: $max-width;
  min-width: $min-width;

  height: 100vh;
  min-height: 375px;
  padding: 0 calc((100% - 1440px) / 2);
  margin: 0;

  transition: 0.5s ease-in-out;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $max-width) {
    width: 100%;
    padding: 0;
  }

  @media screen and (max-width: $change-style-width) {
    flex-flow: column-reverse nowrap;
  }
}

.leftSide {
  @include box-style();
  width: 100%;
  max-width: 517px;
  min-width: $min-width;

  height: 100vh;
  padding: 40px 0;

  background-color: #ececec;
  border-radius: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $change-style-width) {
    max-width: 100%;
    padding-top: 24px;
    padding-bottom: 38px;
    border-radius: 32px 32px 0px 0px;
  }

  @media screen and (max-height: 600px) {
    padding: 15px 0;
  }
}

.leftWrapper {
  @include box-style();
  height: 100%;
  padding-right: 40px;
  background-color: transparent;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: $change-form-width) {
    padding-right: 0;
    align-items: center;
  }
}

.rightSide {
  @include box-style();
  width: 100%;
  max-width: 923px;
  min-width: $min-width;

  height: 100vh;
  min-height: 150px;
  max-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $change-form-width) {
    max-height: 268px;
  }

  @media screen and (max-width: $change-style-width) and (max-height: 450px) {
    display: none;
  }
}

.mainImage {
  display: block;
  @include box-style();
  width: 100%;
  height: 100%;

  object-fit: contain;
  object-position: center;
}

.logo {
  display: block;
  @include box-style();
  width: 218px;
  height: 30px;
  align-self: flex-start;

  @media screen and (max-width: $change-form-width) {
    width: 137px;
    height: 35px;
    align-self: center;
  }

  @media screen and (max-height: 600px) {
    height: 28px;
  }
}

.bottomText {
  @include box-style();
  padding-top: 24px;
  @include text-Noto-14-18();
  color: $color-gray-dark;

  @media screen and (max-height: 600px) {
    padding-top: 10px;
  }
}

.phone {
  @include box-style();
  margin-left: 8px;
  @include text-Noto-14-18();
  color: $color-black;
}