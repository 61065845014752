.errorWrapper {
  min-width: 95px;
}

.value {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
  margin: 0;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #ECECEC;
  max-width: 128px;
}

.description {
  padding: 0;
  margin: 5px 0 0 0;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #ADADAD;
}