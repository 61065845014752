@font-face {
  src: url('./NotoSans/NotoSans-Regular.ttf') format('truetype');
  font-family: 'Noto Sans';
  font-weight: normal;
}

@font-face {
  src: url('./NotoSans/NotoSans-Bold.ttf') format('truetype');
  font-family: 'Noto Sans';
  font-weight: bold;
}

@font-face {
  src:
    url('./Roboto/Roboto-Regular.woff2') format('woff2'),
    url('./Roboto/Roboto-Regular.woff') format('woff'),
    url('./Roboto/Roboto-Regular.ttf') format('truetype');
  font-family: 'Roboto';
  font-weight: normal;
}

