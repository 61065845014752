.titleCardWrapper {
  display: flex;
  align-items: center;
  max-width: 315px;
  & img {
    width: 40px;
    height: 40px;
  }
}

.text {
  user-select: none;
  margin: 0 0 0 10px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ECECEC;
}