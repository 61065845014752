.wrapperBell {
  position: relative;
  cursor: pointer;

  .countBell {
    position: absolute;
    top: 0;
    right: 0;

    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;

    border-radius: 50%;
    background-color: #D25151;

    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}