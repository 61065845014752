@import "../styles/mixins.module";

.modalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  @include section-style();
  height: 100%;

  background-color: rgba(255, 255, 255, 0.08);
  background-attachment: fixed;

  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // background: #ffffff14;
}

.modalContainer {
  position: relative;
  z-index: 101;

  @include box-style();
  width: 100%;
  height: 100%;
}