@import "../../styles/const.module";
@import "../../styles/mixins.module";

.cardsPanel {
  position: relative;

  @include box-style();
  padding: 24px 32px;

  flex: 1 1 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  @media screen and (max-width: 900px) {
    padding: 12px 10px;
  }

  @media screen and (max-width: $change-header-width) {
    padding: 0 16px;
    justify-content: center;
  }  
}

.empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;

  text-align: center;
  font-family: 'Noto Sans', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #2b2b2b;
}

