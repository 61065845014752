.wrapperClimate {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 8px;
  list-style-type: none;
}

.blockVariableClimate {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
  align-items: center;
  min-width: 50px;
  max-width: fit-content;
  gap: 8px;
}

.description {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  margin: 2px 0 0 0;
}
