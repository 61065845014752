.statusIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 10px;
    height: 10px;
  }
}

.green {
  @extend .statusIcon;

  & svg circle {
    fill: #60BF69;
  }
}

.red {
  @extend .statusIcon;

  & svg circle {
    fill: #D25151;
  }
}

.yellow {
  @extend .statusIcon;

  & svg circle {
    fill: #DAA212;
  }
}

.blue {
  @extend .statusIcon;

  & svg circle {
    fill: #51B3D2;
  }
}