@import "../../../styles/mixins.module";
@import "../../../styles/const.module";

.roomsWrapper {
  @include box-style();
  margin-top: 40px;
  overflow: hidden;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.roomWrapper {
  @include box-style();
  padding: 8px;
  margin-bottom: 16px;

  display: inline-flex;
  align-items: center;

  border-radius: 4px;
  background-color: transparent;
  color: $color-gray-medium;

  &:hover {
    cursor: pointer;
  }

  &_active {
    background-color: $color-green1;
    color: white;
    transition: 0.4s linear;
  }
}

.text {
  @include box-style();
  margin-left: 8px;

  font-family: 'Noto Sans', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    color: white;
  }
}

.img {
  display: block;
  @include box-style();
  width: 26px;
  height: 26px;
}

.roomsWrapperTop {
  @include box-style();
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.roomTop {
  @include box-style();
  width: 61px;
  height: 57px;
  padding: 8px;

  flex: none;
  border-radius: 8px;
  background-color: $color-black;
  color: $color-gray-medium;    

  &:hover {
    cursor: pointer;
  }

  & + & {
    margin-left: 8px;
  }

  &_active {
    background-color: $color-green1;
    color: white;
    transition: 0.4s linear;
  }

  &__image {
    object-fit: contain;
    object-position: center;

    display: block;
    @include box-style();
    width: 24px;
    height: 22px;
    margin: 0 auto;
  }

  &__text {
    @include box-style();
    margin-top: 5px;
    transition: none;

    font-family: 'Noto Sans', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
}
