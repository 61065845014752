@import '../../styles/const.module';

.commonButton {
  cursor: pointer;
  padding: 8px 17px;
  font-family: "Noto Sans";
  font-size: 14px;
  line-height: 18px;
  background-color: $color-green1;
  border-radius: 8px;
  color: #FFF;
  border: 0;
}

.commonButton:hover {
  background-color: #59b162;
}