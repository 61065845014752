@import "../../../styles/mixins.module";

@mixin btn-user-form($width, $height) {
  @include button-style();
  width: $width;
  height: $height;

  background-color: inherit;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    opacity: 0.7;
  }
}

.userForm {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  @include box-style();
  min-width: 375px;
  width: 100%;
  height: 240px;

  background-color: $color-black-dark;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);  
  border-radius: 0px 0px 32px 32px;
}

.buttonGroup {
  @include box-style;
  width: 100%;
  padding: 16px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.buttonClose {
  @include btn-user-form(24px, 24px);
  background-image: url(../images/btn-close.svg);
  background-size: 95%;
}

.buttonLogout {
  @include btn-user-form(16px, 16px);
  background-image: url(../images/btn-logout.svg);
  background-size: 100%;
}

.wrapperAvatar {
  position: relative;

  @include box-style();
  width: 80px;
  height: 80px;
  margin: 0 auto;
  font-size: 0;
}

.avatar {
  display: block;
  object-fit: cover;
  object-position: center;
  overflow: hidden;

  @include box-style();
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.online {
  position: absolute;
  right: 0;
  bottom: 0;

  @include box-style();
  width: 24px;
  height: 24px;

  border-radius: 50%;
  background-color: $color-green1;
  background-image: url(../images/camera.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center;
}

.name {
  @include box-style();
  margin-top: 8px;  

  font-family: 'Noto Sans', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $color-gray-light;  
}

.upload{
  display: none;
}

.uploadLabel {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}