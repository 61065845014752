@import "../../../styles/mixins.module";
@import "../../../styles/const.module";

@mixin font-noto-gray($size, $weight, $line) {
  font-family: 'Noto Sans', Arial, sans-serif;
  font-style: normal;
  font-size: $size;
  font-weight: $weight;
  line-height: $line;
  color: $color-gray-light;
}

@mixin flex-ellipsis() {
  min-width: 0;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Горизонтальный список
.propertyList {
  @include box-style();
  width: 100%;
  min-width: 0;
  flex: 1;
  overflow: hidden;

  align-self: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.property {
  position: relative;
  @include box-style();
  min-width: 0;
  max-width: 200px;
  margin-right: 10px;

  flex: 1;
  overflow: hidden;
  cursor: pointer;

  &__activeSign {
    position: absolute;
    left: 0;
    bottom: -3px;
    z-index: 2;
  
    @include box-style();
    min-width: 0;
    width: 100%;
    height: 5px;
  
    overflow: hidden;
    flex: 1;
    background-color: $color-green1;
    border-radius: 3px;
  }  

  &__name {
    @include box-style();
    padding-bottom: 4px;
    @include flex-ellipsis();
    //overflow: hidden;

    @include font-noto-gray($size: 16px, $weight: 600, $line: 20px);
    text-align: left;
  }

  &__address {
    @include box-style();
    @include flex-ellipsis();
    //overflow: hidden;

    font-family: 'Roboto', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $color-gray-light;
    text-align: left;
  }
}

.propertyVertical {
  position: absolute;
  top: 3px;
  left: 72px;
  z-index: 100;

  @include box-style();
  width: calc(100% - 140px);
  min-width: 226px;
  max-height: 90vh;
  padding: 24px 16px;
  overflow-y: scroll;

  background-color: $color-black;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
  border-radius: 8px; 
}

.option {
  @include box-style();
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  & + & {
    margin-top: 16px;
  }

  &__activeSign {
    @include box-style();
    width: 24px;
    height: 24px;
    margin-left: 16px;
    flex: none;
    min-width: 0;

    border-radius: 50%;  
    background-color: $color-green1;
    background-image: url("../images/tick.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &__info {
    @include box-style();
    flex: 1 1 0;
    min-width: 0;
  }

  &__name {
    @include box-style();
    padding-bottom: 2px;
    @include font-noto-gray($size: 16px, $weight: 500, $line: 18px);
    text-align: left;
    @include flex-ellipsis();
  }

  &__address {
    @include box-style();
    @include font-noto-gray($size: 12px, $weight: normal, $line: 18px);
    text-align: left;
    @include flex-ellipsis();
  }
}

.dropDown {
  @include box-style();
  min-width: 0;
  max-width: 196px;
  min-height: 36px;

  flex: 1 1 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &__info {
    @include box-style();
    max-width: 156px;
    @include flex-ellipsis();
  }

  &__name {
    @include box-style();
    @include font-noto-gray($size: 16px, $weight: 500, $line: 18px);
    text-align: center;
    @include flex-ellipsis();
  }

  &__address {
    @include box-style();
    @include font-noto-gray($size: 12px, $weight: normal, $line: 16px);
    text-align: center;
    @include flex-ellipsis();
  }

  &__button {
    @include button-style();
    width: 32px;
    height: 32px;
    flex: none;

    background-image: url('../images/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;

    &:hover {
      opacity: 0.5;
    }
  }
}
