.blockContainer {
  display: flex;
  gap: 24px;
  align-items: center;
}

.blockDescription {
  font-family: 'Noto Sans';
  font-size: 14px;
  line-height: 18px;
  color: #FFF;
}

.questionMarkContainer {
  position: relative;
}