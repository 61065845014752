.description {
  margin: 2px 0 0 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #ADADAD;
}
.container {
  display: flex;
  align-items: center;
}
.offline {
  color: red;
  
} 
.dot {
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    margin-right: 4px;
   }