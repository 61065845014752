@import "../../../../styles/mixins.module";
@import "../../../../styles/const.module";

.form {
  @include box-style();
  width: 301px;
  background-color: transparent;

  @media screen and (max-width: $change-style-width) {
    width: 343px;
  }
}

.title {
  @include box-style();
  padding-bottom: 32px;
  font-family: 'Noto Sans', Arial, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 52px;
  color: $color-black;

  @media screen and (max-width: $change-form-width) {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  @media screen and (max-height: 600px) {
    padding-bottom: 16px;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  }
}

.tipText {
  @include box-style();
  padding-bottom: 24px;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  color: $color-gray-dark;

  @media screen and (max-width: $change-form-width) {
    font-family: 'Noto Sans', Arial, sans-serif;
    line-height: 16px;
    text-align: center;
  }

  @media screen and (max-height: 600px) {
    padding-bottom: 10px;
  }
}

.tipTextError {
  padding-top: 8px;

  font-family: 'Roboto', Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: $color-black;

  @media screen and (max-width: $change-form-width) {
    line-height: 16px;
  }
}

.link {
  margin-left: 8px;
  text-decoration: none;
  @include text-Noto-14-18();
  color: $color-black-dark;
}

.text {
  @include box-style();
  padding-top: 24px;
  @include text-Noto-14-18();
  color: $color-gray-dark;

  @media screen and (max-width: $change-form-width) {
    text-align: center;
  }

  @media screen and (max-height: 600px) {
    padding-top: 10px;
  }
}

.button {
  @include button-style();
  width: 100%;
  height: 40px;
  margin-top: 24px;

  background-color: #60bf69;
  border-radius: 4px;

  @include text-Noto-14-18();
  color: #fff;

  @media screen and (max-width: $change-form-width) {
    height: 50px;
  }

  @media screen and (max-height: 600px) {
    margin-top: 10px;
  }

  &:disabled {
    background-color: #8d998d;
  }

  &:hover {
    background-color: #498d4f;
  }

  &:disabled:hover {
    background-color: #8d998d;
    cursor: default;
  }
}

.errorWrapper {
  @include box-style();
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.errorText {
  @include box-style();
  margin-left: 8px;

  font-family: 'Noto Sans', Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #D25151;

  @media screen and (max-width: $change-form-width) {
    font-weight: 500;
    line-height: 18px;
  }
}
