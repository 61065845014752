@import "../../styles/mixins.module";
@import "../../styles/const.module";

.wrapper {
  position: fixed;
  width: 300px;
  max-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  left: 18px;
  bottom: 18px;
  gap: 16px;
  z-index: 1000000;
}