@import "../../../../styles/mixins.module";
@import "../../../../styles/const.module";

.inputWrapper {
  @include box-style();
  width: 100%;
  height: 40px;
  padding-right: 8px;
  margin-bottom: 16px;

  border: solid 1px #ADADAD;
  border-radius: 4px;
  background-color: white;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (max-width: $change-form-width) {
    height: 48px;
  }

  @media screen and (max-height: 600px) {
    margin-bottom: 8px;
  }
}

.input {
  @include input-style();
  width: 100%;
  height: 90%;
  margin-left: 10px;

  background-color: #fff;
  @include text-Noto-14-18();
  color: $color-black-dark;

  &::placeholder {
    @include text-Noto-14-18();
    color: $color-gray-dark;
  }

  @media screen and (max-width: $change-form-width) {
    line-height: 20px;
  }
}

.inputWrapper:focus-within {
  border: 1px solid #60BF69;
}

.icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.error {
  display: block;
  @include box-style();
  padding-top: 5px;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: #D25151;

  @media screen and (max-width: $change-form-width) {
    font-family: 'Noto Sans', Arial, sans-serif;
    line-height: 16px;
    text-align: center;
  }
}