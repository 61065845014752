@import "../../styles/const.module";
@import "../../styles/mixins.module";

.wrapper {
  @include box-style();
  width: 301px;
  background-color: transparent;

  @media screen and (max-width: $change-style-width) {
    width: 343px;
  }
}

.title {
  @include box-style();
  padding-bottom: 32px;

  font-family: 'Noto Sans', Arial, sans-serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  color: $color-black;

  @media screen and (max-width: $change-form-width) {
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
    text-align: center;
  }
}

.sendMailTip {
  @include box-style();
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: $color-black;

  @media screen and (max-width: $change-form-width) {
    font-family: 'Noto Sans', Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
  }
}

.link {
  @include link-style();
  padding-top: 32px;

  font-family: 'Roboto', Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $color-black-dark;

  &:hover {
    opacity: .8;
  }

  @media screen and (max-width: $change-form-width) {
    font-size: 16px;
    text-align: center;
  }
}


