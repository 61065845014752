@import "../../styles/mixins.module";

@mixin custom-toolbar {
  &::-webkit-scrollbar {
    background-color: $color-gray-medium;
    width: 6px;
    scrollbar-width: 6px;
    padding: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #2f2e2e;
    border-radius: 15px;
  }  
}

.modal {
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  min-width: 375px;
  height: 100%;

  background-color: $color-black-dark;
  color: #fff;

  overflow-x: hidden;
  overflow-y: scroll;
  @include custom-toolbar();
}

.modalHeader {
  position: fixed;
  top: 0;
  left: 0;

  @include box-style();
  width: 100%;
  height: 44px;
  background-color: inherit;
}

.title {
  @include box-style();
  font-family: 'Noto Sans', Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  color: $color-gray-light;  
}

.closeButton {
  position: absolute;
  top: 15px;
  left: 20px;

  object-fit: cover;
  object-position: center;

  display: block;
  @include box-style();
  width: 16px;
  height: 14px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.modalDataContainer {
  @include box-style();
  width: 100%;
  height: 100%;
  min-width: 0;
  padding: 24px 16px;
  margin-top: 44px;

  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  @include custom-toolbar();
}

.notificationBlock {
  @include box-style();
  width: 100%;
  min-width: 0;

  overflow: hidden;
  flex: 1;

  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: stretch;

  & + & {
    margin-top: 16px;
  }

  &__frame {
    object-fit: none;
    object-position: 6px center;

    display: block;
    @include box-style();
    width: 9px;
    height: 9px;

    flex-basis: 29px;
    align-self: center;
    cursor: pointer;
  }

  &__item {
    @include box-style();
    flex: 1 1 0;
  }

  &__date {
    @include box-style();
    margin-bottom: 8px;

    font-family: 'Noto Sans', Arila, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray-medium;    
  }

  &__text {
    @include box-style();
    max-height: 49px;
    margin-bottom: 16px;

    font-family: 'Noto Sans', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $color-gray-light;

    flex: 1;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
