@import "../../../styles/mixins.module";
@import "../../../styles/const.module";

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ring {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-color: black transparent #fff transparent;
  animation: ring 1.2s linear infinite;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}