@import "../../styles/mixins.module";

@mixin text-modal() {
  font-family: 'Roboto', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;

  width: 338px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  background-color: $color-black-dark;
  color: #fff;

  &::-webkit-scrollbar {
    background-color: $color-gray-medium;
    width: 6px;
    scrollbar-width: 6px;
    padding: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #2f2e2e;
    border-radius: 15px;
  }
}

.modalDataContainer {
  margin: 40px 32px;
  max-height: calc(100vh - 80px);
  height: 100%;
}

.title {
  @include box-style();
  margin-left: 29px;
  margin-bottom: 16px;

  font-family: 'Noto Sans', Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $color-gray-light;  
}

.closeButton {
  position: absolute;
  top: 22px;
  right: 38px;

  object-fit: cover;
  object-position: center;

  display: block;
  @include box-style();
  width: 20px;
  height: 20px;

  cursor: pointer;
}

.notificationBlock {
  @include box-style();

  display: flex;
  align-items: flex-end;

  & + & {
    margin-top: 16px;
  }

  &__frame {
    object-fit: none;
    object-position: 0px center;

    display: block;
    @include box-style();
    width: 29px;
    align-self: center;
    cursor: pointer;
  }

  &__item {
    @include box-style();
    width: 245px;
  }

  &__date {
    @include box-style();
    margin-bottom: 8px;

    @include text-modal();
    color: $color-gray-medium;    
  }

  &__text {
    @include box-style();
    margin-bottom: 16px;
    @include text-modal();
    color: $color-gray-light;
  }
}