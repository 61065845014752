@import "../../styles/mixins.module";
@import "../../styles/const.module";

.header {
  position: relative;
  @include box-style();
  width: 100%;
  height: 78px;
  padding: 24px 32px;
  padding-bottom: 0;
  border-bottom: 1px solid $color-gray-medium;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    padding: 12px 16px;
    padding-bottom: 0;
  }

  @media screen and (max-width: $change-header-width) {
    height: 44px;
    padding: 0 16px;
    margin-top: 9px;
    margin-bottom: 32px;
    border: none;
  }
}



