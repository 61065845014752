@import "../../styles/mixins.module";
@import "../../styles/const.module";

.main {
  position: relative;
  @include section-style();
  min-height: 100vh;
  max-width: max-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: stretch;

  background-color: $color-black-dark;
}

.container {
  @include box-style();
  width: 100%;
  min-width: 0;
  flex: 1 1 0;
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;
}