.switchWrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 95px;
  //margin: 8px 30px 8px 0;
}

.text {
  margin: 0 0 0 10px;
  color: #ECECEC;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
}

.switchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 20px;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  margin: 10px 0;
}

.switchLabel .switchButton {
  content: '';
  position: absolute;
  top: 3px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switchCheckbox:checked + .switchLabel .switchButton {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchLabel:active .switchButton {
  width: 18px;
}