@import "../../styles/mixins.module";
@import "../../styles/const.module";

@mixin header-style() {
  @include box-style();
  height: 78px;
  padding: 24px;
  border-bottom: 1px solid $color-gray-medium;
}

@mixin main-style {
  @include box-style();
  flex-grow: 2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin panel-style() {
  position: relative;
  @include box-style();

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  background-color: $color-black;
}

.panelLarge {
  @include panel-style();
  min-height: 100vh;
  min-width: 232px;
}

.panelSmall {
  @include panel-style();
  min-height: 100vh;
  min-width: 87px;
}

.headerLarge {
  @include header-style();
  padding-left: 32px;
  & img {
    width: 150px;
  }
}

.headerSmall {
  @include header-style();
  & img {
    width: 80px;
  }
}

.mainLarge {
  @include main-style();
  padding: 32px;
  padding-top: 40px;
}

.mainSmall {
  @include main-style();
  padding: 24px;
  padding-top: 40px;
}

.logoutWrapper {
  margin: 40px 0 20px;
}

.disclosure {
  position: absolute;
  right: 0;
  top: 50%;
  background-color: #1A1A1A;
  width: 30px;
  height: 60px;
  border-radius: 100px 0 0 100px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  border: none;
}

.arrow {
  transition: .3s;

  &:hover {
    transform: scale(1.2);
    transition: .3s;
  }
}

.panelTop {
  @include box-style();
  width: 100%;
  padding: 0 16px;
  margin-bottom: 24px;
}