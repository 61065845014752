.popup {
  position: relative;
  padding: 16px;
  border-radius: 8px;
  background-color: #282828;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform .25s linear;
  z-index: 100000;
  bottom: 0;
}

.popup_hide {
  transition: transform .25s linear;
  transform: translateX(-110vw);
}

.messageWrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  & img {
    width: 24px;
    height: 24px;
  }
}

.title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  margin: 0;
  padding: 0;
}

.closeIcon {
  width: 14px;
  height: 14px;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: white;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.05));
  border-radius: 50%;
  cursor: pointer;
}