@import "./const.module";

@mixin section-style {
  box-sizing: border-box;
  width: 100%;
  min-width: $min-width;

  padding: 0 calc((100% - 1440px) / 2);
  margin: 0;
  transition: 0.5s ease-in-out;

  @media screen and (max-width: $max-width) {
    width: 100%;
    padding: 0;
  }
}

@mixin box-style() {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  transition: 0.5s ease-in-out;
}

@mixin button-style() {
  @include box-style();
  display: block;
  border: none;
  outline: none;
  box-shadow: none;

  &:hover {
    cursor: pointer;
  }
}

@mixin input-style() {
  @include box-style();
  display: block;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
}

@mixin link-style() {
  text-decoration: none;
  display: block;
  @include box-style();
}

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin text-Noto-14-18() {
  font-family: 'Noto Sans', Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}