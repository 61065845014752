.questionMarkContainer:hover {
  opacity: .9;
}

.tooltip {
  position: relative;
  z-index: 1000000000;
  display: flex;
  cursor: pointer;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  outline: 1px solid #fff;
  color: #fff;
  transition: all .3s ease;
  text-transform: uppercase;
  text-decoration: none;

  // TOOLTIP BOTTOM TRIANGLE
  &:before {
    content: "";
    z-index: 1000000000;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    left: 50%;
    transform: translate3d(-50%, 0%, 0);
    transition: all .3s ease;
    transition-delay: .1s;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #282828 transparent transparent transparent;
  }

  // TOOLTIP BUBBLE
  &:after {
    text-transform: none;
    content: attr(datatype);
    z-index: 1000000000;
    font-size: 14px;
    position: absolute;
    color: #fff;
    background: #282828;
    padding: 8px 12px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 200px;
    opacity: 0;
    pointer-events: none;
    left: 50%;
    top: 0;
    border-radius: 4px;
    transform: translate3d(-50%, 0%, 0);
    transition: all .3s ease;
    transition-delay: .1s;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);

    // TOOLTIP FADEIN AND TRANSLATION
    &:before,
    &:after {
      opacity: 1;
    }

    &:before {
      transform: translate3d(-50%, calc(-100% - 18px), 0);
    }

    &:after {
      transform: translate3d(-50%, calc(-100% - 16px), 0);
    }
  }
}