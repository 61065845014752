@import "../../../styles/mixins.module";
@import "../../../styles/const.module";

@mixin font-Roboto-12-18() {
  font-family: 'Roboto', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.description {
  @include box-style();
  margin-top: 32px;
  margin-bottom: 4px;

  @include font-Roboto-12-18();
  color: $color-gray-medium;
}

.phone {
  @include box-style();
  @include link-style();

  @include font-Roboto-12-18();
  color: $color-gray-light;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

