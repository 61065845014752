.allOffWrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 95px;
  margin: 8px 30px 8px 0;
}

.description {
  font-family: Arial,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ECECEC;
  margin: 0 24px 0 0;
}

.descriptionButton{
  border: none;
  outline: none;
  min-height: 32px;
  padding: 8px 17px;
  background: #60BF69;
  border-radius: 8px;
  font-family: Arial,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  transition: .3s;

  &:hover{
    transition: .3s;
    background-color: #498D4F;
  }

  &:disabled {
    background-color: #8D998D;
  }
}